import React from 'react';
import styled from '@emotion/styled';
import {
  CenteredDiv,
  Container,
  ContentWrapper,
  Heading,
  SubHeading,
} from '../helpers';
import NavBar from '../NavBar';
import { Link } from 'react-router-dom';
import { PROJECTS } from './constants';
import { Project } from './Project';
import { Project as ProjectType } from '../interfaces';
import { NAVIGATION } from '../NavBar/constants';

export default function Projects() {
  return (
    <Container>
      <NavBar />
      <ContentWrapper>
        <Heading>
          My <b>Projects</b>
        </Heading>
        <SubHeading>
          <b>Explore</b> some of the <b>projects</b> I've worked on!
        </SubHeading>
        <SubHeading>
          Or if you're in a <b>rush</b>, you can{' '}
          <Link to={NAVIGATION.RESUME.route} style={{ textDecoration: 'none' }}>
            <b style={{ textDecoration: 'underline' }}>
              check out my resume here
            </b>
          </Link>
        </SubHeading>
        <ProjectList>
          {getProjects().map((project: ProjectType) => {
            return <Project project={project} />;
          })}
        </ProjectList>
      </ContentWrapper>
    </Container>
  );

  function getProjects(): ProjectType[] {
    return Object.values(PROJECTS);
  }
}

const ProjectList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
