import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from './Home';
import About from './About';
import Experience from './Experience';
import Contact from './Contact';
import Projects from './Projects';
import { NAVIGATION } from './NavBar/constants';
import Resume from './Resume';

export default function OldSiteRoot() {
  return (
    <Container>
      <Router>
        <Switch>
          <Route path={NAVIGATION.ABOUT.route} component={About} />
          <Route path={NAVIGATION.PROJECTS.route} component={Projects} />
          <Route path={NAVIGATION.EXPERIENCE.route} component={Experience} />
          <Route path={NAVIGATION.RESUME.route} component={Resume} />
          <Route path={NAVIGATION.CONTACT.route} component={Contact} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Container>
  );
}

const Container = styled.div``;
