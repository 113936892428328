import React from 'react';
import styled from '@emotion/styled';
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare';
import { Github } from '@styled-icons/boxicons-logos/Github';
import NavBar from '../NavBar';
import { mediaSm, mediaMd } from '../constants';
import { Email } from '@styled-icons/material-rounded/Email';
import { Container } from '../helpers';

function Home() {
  return (
    <Content>
      <NavBar />
      <ContentWrapper>
        <Name>
          <FirstName>Aniket </FirstName>
          <SecondaryColorText>Jain</SecondaryColorText>
        </Name>
        <Description>Web Developer, Programmer, Creator</Description>
        <SocialMediaLinks>
          <EmailButtonWrapper href="mailto:aniketjn@umich.edu">
            <EmailButton height={50} width={50} />
          </EmailButtonWrapper>
          <LinkedinButtonWrapper
            href="https://www.linkedin.com/in/aniketjn/"
            target="_blank"
          >
            <LinkedinButton height={50} width={50} />
          </LinkedinButtonWrapper>
          <GithubButtonWrapper
            href="https://github.com/aniketjain576"
            target="_blank"
          >
            <GithubButton height={50} width={50} />
          </GithubButtonWrapper>
        </SocialMediaLinks>
      </ContentWrapper>
      <Overlay />
    </Content>
  );
}

export default Home;

const Content = styled(Container)`
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: url('/background.jpg');
  background-attachment: fixed;
  background-size: cover;
`;

const ContentWrapper = styled.div`
  padding: 5em;
  position: relative;
  z-index: 1;
  ${mediaMd} {
    padding: 10vh;
  }
  ${mediaSm} {
    padding: 3em;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(68, 68, 68, 0.85);
`;

const Name = styled.div`
  margin-top: 20vh;
  font-weight: 400;
  font-size: 8em;

  ${mediaMd} {
    margin-top: 20vh;
    font-size: 6.5em;
    text-align: center;
  }
  ${mediaSm} {
    font-size: 5em;
    text-align: center;
  }
`;

const FirstName = styled.text``;

export const SecondaryColorText = styled(FirstName)`
  color: var(--secondary-color);
`;

const Description = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 1.8em;
  background: rgba(73, 73, 73, 0.8);
  box-sizing: border-box;
  padding: 5px 0px 5px 10px;

  ${mediaMd} {
    font-size: 1.5em;
    text-align: center;
    padding: 5px;
  }
  ${mediaSm} {
    font-size: 1.2em;
    text-align: center;
    padding: 5px;
  }
`;

const SocialMediaLinks = styled.div`
  margin-top: 30px;
  display: flex;

  ${mediaMd} {
    justify-content: space-evenly;
  }
`;

const GithubButtonWrapper = styled.a``;

const LinkedinButtonWrapper = styled(GithubButtonWrapper)``;

const EmailButtonWrapper = styled(GithubButtonWrapper)``;

const GithubButton = styled(Github)`
  cursor: pointer;
  color: white;
  padding: 10px;
  :hover {
    color: var(--secondary-color);
    transition: color 500ms ease-out;
  }
`;

const LinkedinButton = styled(LinkedinSquare)`
  cursor: pointer;
  color: white;
  padding: 10px;
  :hover {
    color: var(--secondary-color);
    transition: color 500ms ease-out;
  }
`;

const EmailButton = styled(Email)`
  cursor: pointer;
  color: white;
  padding: 10px;
  :hover {
    color: var(--secondary-color);
    transition: color 500ms ease-out;
  }
`;
