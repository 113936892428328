import styled from '@emotion/styled';
import { mediaLg, mediaMd, mediaSm } from './constants';

export const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  padding: 3.5em;
  position: relative;
  z-index: 1;
  ${mediaMd} {
    padding: 10vh;
  }
  ${mediaSm} {
    padding: 3.5em;
  }
`;

export const Heading = styled.div`
  font-size: 4.4em;
  font-weight: bold;
  ${mediaLg} {
    text-align: center;
    font-size: 4.5em;
  }
  ${mediaSm} {
    font-size: 3.2em;
  }
`;

export const SubHeading = styled.div`
  margin-top: 15px;
  background: rgba(85, 85, 85, 0.6);
  box-sizing: border-box;
  font-size: 1.3em;
  padding: 10px 50px;
  display: table;
  ${mediaMd} {
    display: block;
    font-size: 1.2em;
    text-align: center;
  }
  ${mediaSm} {
    font-size: 1em;
    padding: 10px 20px;
  }
`;

export const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
`;
