import React, { useState } from 'react';
import styled from '@emotion/styled';
import { HorizontalSquigglyLine, VerticalSquigglyLine } from './SquigglyLine';
import { Heading as HeadingTemplate } from '../helpers';
import { mediaMd, mediaSm } from '../constants';

export default function Introduction() {
  return (
    <Container>
      <HeadingWrapper>
        <Heading>Hi! Nice to meet you!</Heading>
      </HeadingWrapper>
      <Intro>
        <Blurb>
          <Para>
            My name is Aniket and I'm a junior studying Computer Science,
            Economics, and Entrepreneurship at the University of Michigan, Ann
            Arbor.
          </Para>
          <Para>
            I’m passionate about coding and product management, and thrive in
            dynamic, fast-paced environments operating in the intersection of
            technology and impactful product development.
          </Para>
          <Para>
            Listen to my piano cover of Hall of Fame while scrolling down to
            read more about my interests and hobbies!
          </Para>
        </Blurb>
        {window.innerWidth > 800 ? (
          <VerticalSquigglyLine />
        ) : (
          <HorizontalSquigglyLine />
        )}
        <Portrait src="/portrait.jpg" />
      </Intro>
      <HallOfFameWrapper>
        <HallOfFame
          style={{
            borderRadius: '15px',
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.4)',
          }}
          src="hall_of_fame.mp3"
          controls
          controlsList="nodownload"
        />
      </HallOfFameWrapper>
    </Container>
  );
}

const Container = styled.div``;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${mediaMd} {
    margin-bottom: 28px;
  }

  ${mediaSm} {
    margin-bottom: 12px;
  }
`;

const Heading = styled(HeadingTemplate)`
  font-size: 44px;

  ${mediaMd} {
    font-size: 40px;
  }

  ${mediaSm} {
    font-size: 28px;
  }
`;

const Intro = styled.div`
  display: flex;
  align-items: center;

  ${mediaSm} {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const Blurb = styled.div`
  margin-right: 60px;

  ${mediaMd} {
    margin-right: 40px;
  }

  ${mediaSm} {
    margin-right: 0;
    margin-top: 10px;
    text-align: center;
  }
`;

const Portrait = styled.img`
  margin-left: 60px;
  height: 200px;
  border-radius: 24px;
  box-sizing: border-box;
  border: 2px solid white;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);

  ${mediaMd} {
    height: 175px;
    margin-left: 40px;
  }

  ${mediaSm} {
    height: 135px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
`;

const Para = styled.div`
  font-size: 1em;
  line-height: 24px;
  margin: 40px 0px;
  :first-of-type {
    margin-top: 0px;
  }
  :last-of-type {
    margin-bottom: 0px;
  }

  ${mediaMd} {
    margin: 15px 0px;
    font-size: 0.9em;
  }

  ${mediaSm} {
    font-size: 0.8em;
  }
`;

const HallOfFameWrapper = styled.div`
  margin-top: 25px;

  ${mediaSm} {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
`;

const HallOfFame = styled.audio`
  width: 40%;
  height: 30px;
  outline: none;

  ${mediaSm} {
    width: 100%;
  }
`;
