import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { NavItem } from './constants';
import { mediaSm } from '../constants';

export default function MenuOption({ navItem }: { navItem: NavItem }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Container
      to={navItem.route}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ContentWrapper>
        {window.innerWidth > 800 && <Indicator isHovered={isHovered} />}
        {navItem.menuOption}
      </ContentWrapper>
    </Container>
  );
}

const Container = styled(Link)`
  text-decoration: none;
  color: black;
  :hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled.div`
  font-weight: bold;
  letter-spacing: 1px;
  padding: 16px 0px;
  display: flex;
  align-items: center;
  position: relative;

  ${mediaSm} {
    margin: 0px 10px;
    :last-of-type {
      margin-right: 0px;
    }
  }
`;

const Indicator = styled.div<{ isHovered: boolean }>`
  margin-right: 20px;
  box-sizing: border-box;
  height: 12px;
  width: 6px;
  border: ${({ isHovered }) => (isHovered ? '1px solid black' : null)};
  border-left: none;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;

  ${mediaSm} {
    margin-right: 10px;
  }
`;
