import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Experience as ExperienceType } from './interfaces';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { Globe } from '@styled-icons/entypo/Globe';
import { mediaSm } from '../constants';

export default function Experience({
  experience,
}: {
  experience: ExperienceType;
}) {
  const [websiteHovering, setWebsiteHovering] = useState(false);
  const [githubHovering, setGithubHovering] = useState(false);

  return (
    <Container>
      <ContentWrapper>
        <NameWrapper>
          {window.innerWidth > 800 && !experience.position && (
            <Tag>{experience.tag}</Tag>
          )}
          <Name>{experience.name}</Name>
          {!experience.position && <Date>{experience.date}</Date>}
        </NameWrapper>
        {
          <PositionWrapper>
            {window.innerWidth > 800 && experience.position && (
              <Tag>{experience.tag}</Tag>
            )}
            <Position>{experience.position}</Position>
            {experience.position && <Date>{experience.date}</Date>}
          </PositionWrapper>
        }
        <Body>
          <Image src={experience.image} />
          {window.innerWidth > 800 && <VerticalSpacer />}
          <DescriptionWrapper>
            {experience.description.map((para) => {
              return <Description>{para}</Description>;
            })}
          </DescriptionWrapper>
        </Body>
        {experience.name === 'Steve Jobs AI' && (
          <SteveJobsAudio
            style={{ boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.4)' }}
            src="steve_speech.mp3"
            controls
            controlsList="nodownload"
          />
        )}
        <Links>
          {experience.github && (
            <Button
              href={experience.github}
              target="_blank"
              shouldHaveRightMargin={
                !!experience.github && !!experience.website
              }
              onMouseEnter={() => setGithubHovering(true)}
              onMouseLeave={() => setGithubHovering(false)}
            >
              <GithubIcon hover={githubHovering} />
              Github
            </Button>
          )}
          {experience.website && (
            <Button
              href={experience.website}
              target="_blank"
              onMouseEnter={() => setWebsiteHovering(true)}
              onMouseLeave={() => setWebsiteHovering(false)}
            >
              <GlobeIcon hover={websiteHovering} />
              Website
            </Button>
          )}
        </Links>
      </ContentWrapper>
      {window.innerWidth < 800 &&
        experience.name !== 'Sudoku Solver and Generator' && (
          <HorizontalSpacer />
        )}
    </Container>
  );
}

const SteveJobsAudio = styled.audio`
  width: 60%;
  height: 30px;
  outline: none;
  border-radius: 15px;
  align-self: center;
  margin-top: 12px;

  ${mediaSm} {
    width: 100%;
  }
`;

const Button = styled.a<{ shouldHaveRightMargin?: boolean }>`
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  background: white;
  transition: background 200ms ease, color 200ms ease;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
  margin-right: ${({ shouldHaveRightMargin }) =>
    shouldHaveRightMargin ? '20px' : null};

  :hover {
    background: var(--prussian-blue);
    color: white;
  }

  ${mediaSm} {
    font-size: 0.9em;
  }
`;

const Links = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const NameWrapper = styled.div`
  position: relative;

  ${mediaSm} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const PositionWrapper = styled.div`
  position: relative;
  margin: 12px 0px 16px 0px;

  ${mediaSm} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Tag = styled.div`
  font-size: 0.9em;
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: var(--title);
  text-decoration: underline;
`;

const Date = styled(Tag)`
  left: auto;
  right: 0px;

  ${mediaSm} {
    position: static;
    margin-top: 8px;
  }
`;

const GlobeIcon = styled(Globe)<{ hover: boolean }>`
  transition: color 200ms ease-out;
  height: 20px;
  width: 20px;
  color: ${({ hover }) => (hover ? 'white' : 'black')};
  margin-right: 4px;

  ${mediaSm} {
    height: 15px;
    width: 15px;
  }
`;

const GithubIcon = styled(Github)<{ hover: boolean }>`
  transition: color 200ms ease-out;
  height: 25px;
  width: 25px;
  color: ${({ hover }) => (hover ? 'white' : 'black')};
  margin-right: 4px;

  ${mediaSm} {
    height: 20px;
    width: 20px;
  }
`;

const Position = styled.div`
  font-family: var(--title);
  text-align: center;
  text-decoration: underline;
  font-size: 1.05em;
`;

const VerticalSpacer = styled.div`
  margin: 0px 32px;
  height: 60%;
  width: 2px;
  background: black;
  border-radius: 20px;
`;

const HorizontalSpacer = styled.div`
  background: black;
  border-radius: 20;
  height: 1px;
  width: 80%;
  margin: 16px auto;
`;

const Image = styled.img`
  width: 150px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);

  ${mediaSm} {
    width: 125px;
    margin-bottom: 8px;
  }
`;

const Body = styled.div`
  display: flex;
  align-items: center;

  ${mediaSm} {
    flex-direction: column;
    text-align: center;
  }
`;

const Name = styled.div`
  font-family: var(--title);
  font-size: 1.3em;
  text-align: center;
  font-weight: 600;
`;

const DescriptionWrapper = styled.div``;

const Description = styled.div`
  font-size: 0.8em;
  line-height: 20px;
  margin-bottom: 8px;
  :last-of-type {
    margin-bottom: 0px;
  }

  ${mediaSm} {
    margin-bottom: 4px;
  }
`;

const Container = styled.div`
  box-sizing: border-box;
  margin: 28px 0px;
  width: 100%;
  display: flex;

  ${mediaSm} {
    display: block;
    margin: 0px;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: 16px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
`;
