import React from 'react';
import styled from '@emotion/styled';
import { MenuItems } from '../MenuItem/constants';
import { Helmet } from 'react-helmet';

export default function Menu() {
    return (
        <Container>
            <Helmet>
                <title>The Fuku Bar</title>
            </Helmet>
            <ContentWrapper>
                <Heading>Don't Choose Wisely</Heading>
                {/* <SubHeading>Go Wild</SubHeading> */}
                <MenuItemsWrapper>
                    {Object.values(MenuItems).map((item) => {
                        return (
                            <MenuItem>
                                <MenuItemName>
                                    {item.name}
                                </MenuItemName>
                                <ImageAndDescription>
                                    <ItemImage src={item.image} />
                                    <Description>
                                        {item.description}
                                    </Description>
                                </ImageAndDescription>
                            </MenuItem>
                        )
                    })}
                </MenuItemsWrapper>
                <FootNote>----- The Fuku Bar -----</FootNote>
            </ContentWrapper>
        </Container>
    )
}

const MenuItemsWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Description = styled.div`
    font-family: 'Comfortaa', cursive;   
    font-family: 'Rock Salt', cursive;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 24px;
`;

const ImageAndDescription = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const MenuItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 52px;
    :last-of-type {
        margin-bottom: 36px;
    } 
`;

const ItemImage = styled.img`
    width: 150px;
    margin-right: 20px;
    object-fit: contain;
    border-radius: 16px;
`;

const FootNote = styled.div`
    font-family: 'Dancing Script', cursive;
    text-align: center;
    font-family: 'Permanent Marker', cursive;
`;

const MenuItemName = styled.div`
    font-family: 'Dancing Script', cursive;
    font-family: 'Permanent Marker', cursive;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    text-align: center;
    background: -webkit-linear-gradient(left, gold, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const Container = styled.div`
    background: #4b6cb7;
    background: -webkit-linear-gradient(to right, #182848, #4b6cb7); 
    background: linear-gradient(to right, #182848, #4b6cb7);
    background: -webkit-linear-gradient(to right, #001f3d, #3e5a9b); 
    background: linear-gradient(to right, #001f3d, #3e5a9b);
    background: black;
    padding: 32px;
    color: white;
`;

const ContentWrapper = styled.div``;

const Heading = styled.div`
    font-family: 'Press Start 2P', cursive;
    text-align: center;
    font-weight: bold;
    font-size: 44px;
    margin-bottom: 24px;
    background: -webkit-linear-gradient(left, #f700ff, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

const SubHeading = styled.div`
    font-size: 48px;
    text-align: center;
    margin-top: 8px;
    text-decoration: underline;
    font-weight: bolder;
    background: -webkit-linear-gradient(left, gold, #eee);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
