import React, { useState } from 'react';
import styled from '@emotion/styled';
import NavMenu from './NavMenu';
import { mediaSm } from '../constants';

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container>
      <MenuButton onClick={() => setShowMenu(!showMenu)} showMenu={showMenu}>
        <MenuLineOne showMenu={showMenu} />
        <MenuLineTwo showMenu={showMenu} />
        <MenuLineThree showMenu={showMenu} />
      </MenuButton>
      <NavMenu showMenu={showMenu} />
    </Container>
  );
}

export default NavBar;

const Container = styled.div``;

const MenuButton = styled.div<{ showMenu: boolean }>`
  position: fixed;
  z-index: 3;
  top: 35px;
  right: 35px;
  cursor: pointer;
  transition: transform 500ms ease-out;
  transform: rotate(${({ showMenu }) => (showMenu ? '180deg' : '0deg')});

  ${mediaSm} {
    top: 20px;
    right: 20px;
  }
`;

const MenuLineOne = styled.div<{ showMenu: boolean }>`
  width: 35px;
  height: 3px;
  background: white;
  margin-bottom: 8px;
  transition: transform 500ms ease-out;

  transform: rotate(${({ showMenu }) => (showMenu ? '45deg' : '0deg')})
    translate(${({ showMenu }) => (showMenu ? '9px, 9px' : '0px, 0px')});
`;

const MenuLineTwo = styled(MenuLineOne)`
  transition: opacity 500ms ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 0 : 1)};
`;
const MenuLineThree = styled(MenuLineOne)`
  transform: rotate(${({ showMenu }) => (showMenu ? '-45deg' : '0deg')})
    translate(${({ showMenu }) => (showMenu ? '7px, -6.5px' : '0px, 0px')});
`;
