import { Experiences as ExperiencesType, TAGS } from './interfaces';

export const EXPERIENCES: ExperiencesType = {
  DESAI: {
    name: 'Desai Accelerator',
    position: 'Software Engineer Intern',
    description: [
      'The Desai Accelerator invests in 20 innovative tech startups every year.',
      'Over the summer I have simultaneously built features for 4 startups.',
      'I reduced Leasemagnets’ (YCombinator - Spring, 2021) onboarding time by 15% by automating video creation processes for clients.',
      'I also built Aurora Tights’ wholesale website which increased monthly recurring revenue by 30%.',
    ],
    date: 'May 2021 - Present',
    image: '/assets/desai.png',
    website: 'https://desaiaccelerator.umich.edu/',
    tag: TAGS.internship,
  },
  INTROS: {
    name: 'Intros.ai',
    position: 'Technical Product Manager',
    description: [
      'Intros is automating the way 1:1 connections are made in digital communities.',
      'I conduct customer interviews and use the feedback to ideate new features and communicate product vision. I then break features down into detailed, actionable engineering tickets.',
      "I'm also leading frontend development in Typescript/React using a view - view model - model - data model architecture.",
      'Lastly, I help build our RestFul API in Python/Tornado to expand the features we offer and convert leads into customers.',
    ],
    date: 'December, 2020 - Present',
    image: '/assets/intros.jpg',
    website: 'https://www.intros.ai/',
    tag: TAGS.part_time,
  },
  CHIME: {
    name: 'Chime Menu',
    position: 'Founding Engineer',
    description: [
      'Chime Menu is a digital menu startup handling 100+ contactless online orders a week in the Ann Arbor area.',
      "I'm using Typescript/React and Flask to build and optimize a ~20-page user-facing app and interactive server app. I'm utilizing Redux/Contexts for state management, SCSS modules for BEM-based styling, Rollbar/Google Analystics for error handling, and many more tools.",
    ],
    date: 'September, 2020 - April, 2021',
    image: '/assets/chime.jpg',
    website: 'https://chimemenu.com/',
    tag: TAGS.part_time,
  },
  COVIDOPOLY: {
    name: 'Covidopoly',
    position: 'Software Engineer',
    description: [
      'Covidopoly is an online alternative to Monopoly Deal. It has been played over 1 million times by 500,000+ people in 172+ countries. On top of working at American Express during summer 2020, I helped scale Covidopoly.',
      'Being one of the first five software engineers at Covidopoly, I collaborated with UX designers, graphic designers, and sound engineers to build our mobile application (currently in beta testing) using React Native. I also added features like tracking game highlights to the website using the MERN stack.',
      'Additionally, I participated in deliberations about product launch strategies and helped gorw the user base by 300%, reaching over 200,000 users in 3 months.',
    ],
    image: '/assets/covidopoly.png',
    date: 'June, 2020 - March, 2021',
    website: 'https://www.covidopoly.io/',
    tag: TAGS.part_time,
  },
  AMEX_CREDIT: {
    name: 'American Express',
    position: 'Machine Learning Intern',
    description: [
      "I analyzed numerous economic variables to understand why cardholders' credit scores have decreased over the past 7 years despite their expenditure being the same. I utilized the SHAP and LIME Python libraries to run analyses on XGBoost Decision Trees to pin-point the primary reason machine learning models were going wrong- migration to online spending.",
      "Additionally, I created a tool which enabled developers to visualize how much each variable contributed to a machine learning model's prediction.",
      'During my last 2 weeks, I created a new machine learning model which took economic fluctuations into account and has a predicted accuracy of 94% 10 years in the future.',
    ],
    image: '/assets/amex.png',
    date: 'June, 2020 - September, 2020',
    tag: TAGS.internship,
  },
  AMEX_FRAUD: {
    name: 'American Express',
    position: 'Fraud Risk Management Intern',
    description: [
      "Some AMEX cardholders pose as merchants to reverse charges on their credit cards without the merchant's knowledge, effectively increasing the limit on their cards",
      'I analyzed fraudulent behavior in high-risk industries like technology and jewelry to find patterns that these fraudsters use and devised various strategies to predict this behavior. I used my findings to design a program that calculates the probability of a cardholder commiting this type of fraud. My program has been saving AMEX $6MM per year since 2017.',
    ],
    date: 'December, 2017 - Jan, 2018',
    image: '/assets/amex.png',
    tag: TAGS.internship,
  },
  STEVE_AI: {
    name: 'Steve Jobs AI',
    description: [
      'I was devastated when Steve Jobs passed away...',
      'So I made an AI that talks like him.',
      "It uses Python's text-to-speech library to listen to Jobs' keynote speeches and an n-gram model to train itself before delivering an original speech.",
      'Listen to what he has to say!',
    ],
    image: '/assets/steve_ai.jpg',
    date: 'November, 2019',
    tag: TAGS.personal_project,
  },
  MAZE_MAKER: {
    name: 'Maze Visualizer',
    description: [
      'Get mesmerized by watching an algorithm create a maze!',
      'I implemented a recursive backtracking algorithm using Typescript/React, Redux, and styled components.',
      'The Maze visualizer the first website I made from scratch. This personal website is the second!',
    ],
    image: '/assets/maze.jpg',
    date: 'August, 2020',
    github: 'https://github.com/aniketjain576/Maze-Generator',
    website: 'https://mazealgo.com/',
    tag: TAGS.personal_project,
  },
  SUDOKU: {
    name: 'Sudoku Solver and Generator',
    description: [
      'I created a command line game in C++ that solves and creates Sudoku puzzles. It can make puzzles of 3 different difficulties and solve any sudoku board in under 2 seconds.',
    ],
    date: 'May, 2020',
    image: '/assets/sudoku.jpg',
    github: 'https://github.com/aniketjain576/Sudoku',
    tag: TAGS.personal_project,
  },
};
