enum NAV_OPTIONS {
  HOME = 'HOME',
  ABOUT = 'ABOUT',
  WORK = 'WORK',
  RESUME = 'RESUME',
}

export interface NavItem {
  menuOption: string;
  route: string;
}

type NavigationType = {
  [key in NAV_OPTIONS]: NavItem;
};

interface Navigation extends NavigationType {}

export const NAVIGATION: Navigation = {
  HOME: {
    menuOption: 'Home',
    route: '/',
  },
  ABOUT: {
    menuOption: 'About',
    route: '/about',
  },
  WORK: {
    menuOption: 'Work',
    route: '/work',
  },
  RESUME: {
    menuOption: 'Resume',
    route: '/resume',
  },
};
