import React from 'react';
import styled from '@emotion/styled';
import CSS from 'csstype';
import { mediaMd, mediaSm } from './constants';

export const Container = styled.div`
  background: radial-gradient(
      ellipse 70vw 60vw at 33vw 10vh,
      var(--fiery-orange-rgb) 20vh,
      rgba(227, 152, 94, 0) 80vh
    ),
    radial-gradient(
      ellipse 60vw 60vw at 5vw 5vh,
      var(--crimson-rgb),
      var(--bright-maroon-rgb) 10vw,
      var(--bright-maroon-rgb) 15vw,
      var(--violet-blue-crayola-rgb) 30vw,
      rgba(129, 43, 199, 0) 45vw,
      rgba(129, 43, 199, 0) 60vw
    ),
    linear-gradient(
      130deg,
      var(--violet-blue-crayola),
      white 70vw,
      rgba(255, 255, 255, 0) 100vw
    ),
    radial-gradient(
      ellipse 5vw 8vw at 5vw 100%,
      var(--crimson-rgb),
      var(--bright-maroon-rgb) 5vw,
      var(--bright-maroon-rgb) 10vw,
      var(--violet-blue-crayola-rgb) 15vw,
      rgba(129, 43, 199, 0) 30vw,
      rgba(129, 43, 199, 0) 60vw
    ),
    radial-gradient(
      ellipse 30vw 30vw at 80vw 100%,
      var(--medium-turquoise),
      var(--violet-blue-crayola-rgb) 8vw,
      rgba(129, 43, 199, 0) 20vw
    );

  ${mediaSm} {
    background: radial-gradient(
        ellipse 60vw 60vw at 5vw 5vh,
        var(--crimson-rgb),
        var(--bright-maroon-rgb) 10vw,
        var(--bright-maroon-rgb) 15vw,
        var(--violet-blue-crayola-rgb) 30vw,
        white 60vw,
        rgba(255, 255, 255, 0) 99vh
      ),
      radial-gradient(
        ellipse 5vw 8vw at 5vw 100%,
        var(--crimson-rgb),
        var(--bright-maroon-rgb) 5vw,
        var(--bright-maroon-rgb) 10vw,
        var(--violet-blue-crayola-rgb) 15vw,
        rgba(129, 43, 199, 0) 30vw,
        rgba(129, 43, 199, 0) 60vw
      ),
      radial-gradient(
        ellipse 30vw 30vw at 80vw 100%,
        var(--medium-turquoise),
        var(--violet-blue-crayola-rgb) 8vw,
        rgba(129, 43, 199, 0) 20vw
      );
  }
`;

export const ContentWrapper = styled.div`
  padding: 8em;
  padding-top: 6em;
  position: relative;
  overflow: hidden;

  ${mediaMd} {
    padding: 6em;
  }

  ${mediaSm} {
    padding: 2em;
  }
`;

export const Heading = styled.div`
  color: black;
  font-family: var(--title);
  font-weight: 600;
  font-size: 68px;
  letter-spacing: 3px;

  ${mediaSm} {
    font-size: 44px;
  }
`;

const Dots = styled.img`
  position: absolute;
  bottom: 0;
  left: 8vw;
`;

export function FooterDots({ style }: { style?: CSS.Properties }) {
  return <Dots style={{ ...style }} src="dots_h.svg" />;
}

export function SmallFooterDots({ style }: { style?: CSS.Properties }) {
  return <Dots style={{ ...style }} src="dots_small_h.svg" />;
}
