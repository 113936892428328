export interface Experience {
  name: string;
  position?: string;
  description: string[];
  image: string;
  date: string;
  github?: string;
  website?: string;
  tag: TAGS;
}

export enum TAGS {
  personal_project = 'Personal Project',
  internship = 'Internship',
  part_time = 'Part Time Job',
}

export enum EXPERIENCE_TYPES {
  COVIDOPOLY = 'COVIDOPOLY',
  AMEX_CREDIT = 'AMEX_CREDIT',
  CHIME = 'CHIME',
  AMEX_FRAUD = 'AMEX_FRAUD',
  INTROS = 'INTROS',
  DESAI = 'DESAI',
  STEVE_AI = 'STEVE_AI',
  MAZE_MAKER = 'MAZE_MAKER',
  SUDOKU = 'SUDOKU',
}

type ExperiencesType = {
  [key in EXPERIENCE_TYPES]: Experience;
};

export interface Experiences extends ExperiencesType {}
