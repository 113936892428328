import React from 'react';
import styled from '@emotion/styled';
import { mediaMd, mediaSm } from '../constants';
import { CenteredDiv } from '../helpers';

function Interests() {
  return (
    <Container>
      <Heading>
        Things I <b>Like...</b>
      </Heading>
      <Section>
        <SectionHeader>
          Iced <b>Coffee</b>
        </SectionHeader>
        <Description>
          I've been an iced coffee connoisseur ever since I tasted the one
          served at the Michigan Undergraduate Library.
        </Description>
        <Description>
          Here's a picture of one of my <b>creations</b>
        </Description>
        <CenteredDiv>
          <IcedCoffeeImage src="/iced_coffee.jpg" />
        </CenteredDiv>
      </Section>
      <Section>
        <SectionHeader>
          Listening to <b>Music</b>
        </SectionHeader>
        <Description>
          I love finding new songs that combine <b>rap</b>, <b>pop</b>, and
          <b> hip-hop</b> in innovative ways.
        </Description>
        <Description>
          These are some of my <b>favorites</b>!
        </Description>
        <SpotifyWrapper>
          <Spotify
            src="https://open.spotify.com/embed/playlist/02rWJqlcGaYoEyMTBYb77X"
            width="750"
            height="450"
            frameBorder="0"
            allowTransparency={true}
            allow="encrypted-media"
          />
        </SpotifyWrapper>
        <Note>
          <b>Note:</b> I've been playing the <b>piano</b> for <b>8 years</b> and
          have <b>performed</b> at numerous concerts!
        </Note>
      </Section>
      <Section>
        <SectionHeader>Swimming</SectionHeader>
        <Description>
          I've been a <b>competitive swimmer</b> for six years and participated
          in the
          <b> Indian Nationals</b> in 2018. My favorite strokes are
          <b> freestyle</b> and <b>butterfly</b>.
        </Description>
        <Description>
          This is a picture from my <b>first race</b> at the Regionals!
        </Description>
        <CenteredDiv>
          <DiveImage src="/dive.jpg" />
        </CenteredDiv>
      </Section>
    </Container>
  );
}

export default Interests;

const Container = styled.div`
  margin-top: 40px;
`;

const Heading = styled.div`
  font-size: 3.8em;
  font-weight: bold;
  ${mediaMd} {
    font-size: 3.5em;
  }
  ${mediaSm} {
    font-size: 2.8em;
  }
`;

const Section = styled.div`
  margin-top: 40px;

  ${mediaSm} {
    margin-top: 30px;
  }
`;

const SectionHeader = styled.div`
  font-size: 3.3em;
  margin-bottom: 10px;
  ${mediaMd} {
    text-align: center;
    font-size: 3.2em;
  }
  ${mediaSm} {
    font-size: 2.5em;
  }
`;

const Description = styled.div`
  box-sizing: border-box;
  padding: 10px;
  font-size: 1.3em;
  background: #393939;
  text-align: center;
  ${mediaMd} {
    text-align: center;
    font-size: 1.3em;
  }
  ${mediaSm} {
    font-size: 1em;
  }
`;

const Note = styled(Description)`
  margin-top: 20px;
  ${mediaSm} {
    margin-top: 10px;
  }
`;

const Spotify = styled.iframe`
  margin-top: 40px;
`;

const IcedCoffeeImage = styled.img`
  margin-top: 40px;
  height: 450px;
  border-radius: 20px;
  border: 4px solid var(--secondary-color);
  ${mediaMd} {
    height: 400px;
  }
  ${mediaSm} {
    height: 335px;
  }
`;

const DiveImage = styled.img`
  margin-top: 40px;
  width: 550px;
  border-radius: 20px;
  border: 4px solid var(--secondary-color);

  ${mediaMd} {
    width: 500px;
  }

  ${mediaSm} {
    width: 300px;
  }
`;

const SpotifyWrapper = styled(CenteredDiv)``;
