import React from 'react';
import styled from '@emotion/styled';
import Navigation from '../Navigation';
import { Helmet } from 'react-helmet';
import {
  Container as ContainerTemplate,
  ContentWrapper,
  FooterDots,
  Heading as HeadingTemplate,
  SmallFooterDots,
} from '../helpers';
import { mediaMd, mediaSm } from '../constants';

export default function Resume() {
  return (
    <Container>
      <Helmet>
        <title>Aniket Jain | Resume</title>
      </Helmet>
      <Navigation />
      <ContentWrapper>
        {window.innerWidth > 800 ? (
          <HeaderDots src="dots_h.svg" />
        ) : (
          <HeaderDots src="dots_small_h.svg" />
        )}
        <ResumePageContentWrapper>
          <HeadingWrapper>
            <Heading>Resume</Heading>
            <Button href="/Aniket_Jain_Resume.pdf" download>
              Download Resume
            </Button>
          </HeadingWrapper>
          <ResumeWrapper>
            <ResumeImage src="/resume.jpg" />
          </ResumeWrapper>
        </ResumePageContentWrapper>
        {window.innerWidth > 800 ? <FooterDots /> : <SmallFooterDots />}
      </ContentWrapper>
    </Container>
  );
}

const Container = styled(ContainerTemplate)`
  ${mediaSm} {
    height: 100vh;
  }
`;

const ResumeWrapper = styled.div`
  position: relative;
  z-index: 5;

  ${mediaSm} {
    margin-top: 60px;
  }
`;

const HeaderDots = styled.img`
  position: absolute;
  top: 40px;
  right: 8vw;

  ${mediaSm} {
    top: 110px;
    right: 3vw;
  }
`;

const ResumeImage = styled.img`
  width: 100%;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 40px;

  ${mediaMd} {
    font-size: 40px;
  }

  ${mediaSm} {
    font-size: 28px;
    margin-bottom: 16px;
  }
`;

const ResumePageContentWrapper = styled.div`
  position: relative;
  padding: 0em 3em;

  ${mediaMd} {
    padding: 0em;
  }
`;

const Heading = styled(HeadingTemplate)`
  font-size: 44px;
  margin-bottom: 16px;

  ${mediaSm} {
    margin-bottom: 12px;
  }
`;

const Button = styled.a`
  text-decoration: none;
  color: black;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  background: white;
  transition: background 200ms ease, color 200ms ease;

  :hover {
    background: var(--prussian-blue);
    color: white;
  }

  ${mediaSm} {
    font-size: 0.7em;
    padding: 4px 8px;
  }
`;
