import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Menu from './Menu';

let initialWindowWidth = window.innerWidth;
window.addEventListener('resize', () => {
	if (initialWindowWidth > 800 && window.innerWidth < 800) {
		window.location.reload();
	}
	if (initialWindowWidth < 800 && window.innerWidth > 800) {
		window.location.reload();
	}
});

export default function BarRoot() {
	return (
		<Container>
			<Router>
				<Switch>
					<Route path="/" component={Menu} />
				</Switch>
			</Router>
		</Container>
	);
}

const Container = styled.div``;
