import { Experiences as ExperiencesType } from '../interfaces';

export const EXPERIENCES: ExperiencesType = {
  DESAI: {
    title: 'Desai Accelerator',
    subtitle: 'Software Engineer Intern',
    description: [
      'The Desai Accelerator invests in 20 <b>innovative tech startups</b> every year.',
      'Over the summer I have <b>simultaneously built features</b> for <b>4 startups</b>.',
      'I reduced <b>Leasemagnets’ (YCombinator - Spring, 2021) onboarding time by 60%</b> by ideating and coding features which <b>improved operational efficiency</b>.',
      'I also <b>built Aurora Tights’ wholesale website</b> which <b>increased monthly recurring revenue by 30%</b>.',
    ],
    date: 'May 2021 - Present',
    image: '/assets/desai.png',
    website: 'https://desaiaccelerator.umich.edu/',
  },
  INTROS: {
    title: 'Intros.ai',
    subtitle: 'Technical Product Manager',
    description: [
      'Intros is <b>automating</b> the way <b>1:1 connections</b> are made in <b>digital communities</b>.',
      'I <b>conduct customer interviews</b> and use the feedback to <b>ideate new features</b> and <b>communicate product vision</b>. I then <b>break features down</b> into detailed, <b>actionable engineering tickets</b>.',
      "I'm also <b>leading frontend development</b> in Typescript/React using a <b>view - view model - model - data model architecture</b>.",
      'Lastly, I help build our <b>RestFul API in Python/Tornado</b> to expand the features we offer and <b>convert leads into customers</b>.',
    ],
    date: 'December, 2020 - Present',
    image: '/assets/intros.jpg',
    website: 'https://www.intros.ai/',
  },
  CHIME: {
    title: 'Chime Menu',
    subtitle: 'Founding Engineer',
    description: [
      'Chime Menu is a <b>digital menu startup</b> focused on <b>enhancing</b> the dine-in experience.',
      "I'm using <b>Typescript/React</b> and <b>Flask</b> to build and optimize a <b>~20-page</b> user-facing app and <b>interactive server app</b>. I'm utilizing <b>Redux/Contexts</b> for state management, <b>SCSS modules</b> for BEM-based styling, <b>Rollbar/Google Analystics</b> for error handling, and <b>many more tools</b>.",
      'Chime now handles <b>hundereds of contactless orders</b> every week.',
    ],
    date: 'September, 2020 - April, 2021',
    image: '/assets/chime.jpg',
    website: 'https://chimemenu.com/',
  },
  COVIDOPOLY: {
    title: 'Covidopoly',
    subtitle: 'Software Engineer',
    description: [
      'On top of working at American Express during summer 2020, I helped scale <b>Covidopoly</b></a>, an <b>online game startup</b> focused on bringing to people what they need most in the time of COVID-19: <b>entertainment</b> and <b>comic relief</b>.',
      'Being one of the <b>first five</b> software engineers at Covidopoly, I <b>collaborated</b> with <b>UX designers</b>, <b>graphic designers</b>, and <b>sound engineers</b> to build our <b>mobile application</b> (currently in beta testing) using <b>React Native</b>. I also added features like <b>tracking game highlights</b> to the website using the <b>MERN stack</b>.',
      'Additionally, I participated in deliberations about <b>product launch strategies</b> and helped gorw the user base by <b>300%</b>, reaching over <b>200,000 users</b> in <b>3 months</b>.',
    ],
    image: '/assets/covidopoly.png',
    date: 'June, 2020 - March, 2021',
    website: 'https://www.covidopoly.io/',
  },
  AMEX_CREDIT: {
    title: 'American Express',
    subtitle: 'Machine Learning Intern',
    description: [
      "I analyzed numerous economic variables to understand <b>why cardholders' credit scores have decreased over the past 7 years</b> despite their <b>expenditure being the same</b>. I utilized the <b>SHAP</b> and <b>LIME</b> Python libraries to run analyses on <b>XGBoost Decision Trees</b> to pin-point the primary reason <b>machine learning models were going wrong</b>- migration to online spending.",
      "Additionally, I created a tool which enables developers to <b>visualize</b> how much each <b>variable contributed</b> to a machine learning model's <b>prediction</b>.",
      'During my last 2 weeks, I <b>created a new machine learning model</b> which takes <b>economics fluctuations into account</b> and has a predicted accuracy of <b>95% 10 years</b> in the future.',
    ],
    image: '/assets/amex.png',
    date: 'June, 2020 - September, 2020',
  },
  AMEX_FRAUD: {
    title: 'American Express',
    subtitle: 'Fraud Risk Management Intern',
    description: [
      "Some AMEX cardholders <b>pose as merchants</b> to reverse charges on their credit cards without the merchant's knowledge, effectively <b>increasing the limit</b> on their cards",
      'I <b>analyzed fraudulent behavior</b> in <b>high-risk industries</b> like technology and jewelry to find <b>patterns</b> that these fraudsters use and devised various strategies to <b>predict this behavior</b>. I used my findings to design a program that <b>calculates the probability</b> of a cardholder commiting this type of fraud. My program has been <b>saving</b> AMEX <b>$6MM per year</b> since 2017.',
    ],
    date: 'December, 2017 - Jan, 2018',
    image: '/assets/amex.png',
  },
};
