import React, { useState } from 'react';
import styled from '@emotion/styled';
import OldSiteRoot from './OldSite';
import NewSiteRoot from './NewSite';
import BarRoot from './Bar'

export default function Root() {
  const [useNewSite, setUseNewSite] = useState(true);
  const useRecipe = true;
  return (
    <Container>{useRecipe ? <BarRoot /> : useNewSite ? <NewSiteRoot /> : <OldSiteRoot />}</Container>
  );
}

const Container = styled.div``;
