import { MenuItemType } from "./interfaces";

export const MenuItems: MenuItemType[] = [
    {
        name: 'Tequila Sunrise',
        image: '/tequila_sunrise.png',
        description: "The sun will rise tomorrow but you shouldn't"
    },
    {
        name: 'Long Island Iced Tea',
        image: '/lit.jpeg',
        description: "It's a litty titty Dave Special"
    },
    {
        name: "Malibu Sunset",
        image: '/malibu_sunset.png',
        description: "The sun will set in Mailbu but not on this party"
    },
    {
        name: "Strawberry Daquiri",
        image: '/strawberry_daq.png',
        description: "Grab a straw, grab a berry, it's Strawbri's hbd get yourself a daquiri"
    },
    {
        name: "Raspberry Lemonade Margarita",
        image: '/rasp_lemon_marg.png',
        description: "Raspberry and lemonade and you won't taste the alc, drink it"
    }
]