enum NAV_OPTIONS {
  HOME = 'HOME',
  ABOUT = 'ABOUT',
  EXPERIENCE = 'EXPERIENCE',
  PROJECTS = 'PROJECTS',
  RESUME = 'RESUME',
  CONTACT = 'CONTACT',
}

export interface NavItem {
  menuOption: string;
  route: string;
}

type NavigationType = {
  [key in NAV_OPTIONS]: NavItem;
};

interface Navigation extends NavigationType {}

export const NAVIGATION: Navigation = {
  HOME: {
    menuOption: 'Home',
    route: '/',
  },
  ABOUT: {
    menuOption: 'About',
    route: '/about',
  },
  EXPERIENCE: {
    menuOption: 'Work Experience',
    route: '/experience',
  },
  PROJECTS: {
    menuOption: 'Personal Projects',
    route: '/projects',
  },
  RESUME: {
    menuOption: 'Resume',
    route: '/resume',
  },
  CONTACT: {
    menuOption: 'Contact Me',
    route: '/contact',
  },
};
