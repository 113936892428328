import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import Home from './Home';
import { NAVIGATION } from './Navigation/constants';
import About from './About';
import Work from './Work';
import Resume from './Resume';

let initialWindowWidth = window.innerWidth;
window.addEventListener('resize', () => {
  if (initialWindowWidth > 800 && window.innerWidth < 800) {
    window.location.reload();
  }
  if (initialWindowWidth < 800 && window.innerWidth > 800) {
    window.location.reload();
  }
});

export default function NewSiteRoot() {
  return (
    <Container>
      <Router>
        <Switch>
          <Route path={NAVIGATION.ABOUT.route} component={About} />
          <Route path={NAVIGATION.WORK.route} component={Work} />
          <Route path={NAVIGATION.RESUME.route} component={Resume} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </Container>
  );
}

const Container = styled.div``;
