import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { mediaMd, mediaSm, mediaLg } from '../constants';
import { NAVIGATION } from '../NavBar/constants';

function Introduction() {
  return (
    <Container>
      <Intro>
        <PortraitWrapper>
          <Portrait />
        </PortraitWrapper>
        <Blurb>
          <LargePara>
            Hi! Nice to meet you! My name is Aniket and I'm a sophomore studying{' '}
            <b>Computer Science</b>, <b>Economics</b>, and
            <b> Entrepreneurship </b>
            at the <b>University of Michigan</b>, Ann Arbor.
          </LargePara>
          <Para>
            I’m passionate about coding and enjoy dynamic, fast-paced
            environments operating in the intersection of
            <b> technology and impactful product development</b>.
          </Para>
        </Blurb>
      </Intro>
      <Section
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SmallPara style={{ margin: '0 auto' }}>
          Listen to my<b> piano cover of Hall of Fame</b> while scrolling down
          to read more about my interests and hobbies!
        </SmallPara>
        <SmallPara style={{ margin: '0 auto' }}>
          And please <b>feel free</b> to{' '}
          <Link
            to={NAVIGATION.CONTACT.route}
            style={{ textDecoration: 'none' }}
          >
            <b style={{ textDecoration: 'underline' }}>hit me up</b>
          </Link>{' '}
          any time!
        </SmallPara>
        <HallOfFame src="hall_of_fame.mp3" controls controlsList="nodownload" />
      </Section>
    </Container>
  );
}

export default Introduction;

const Container = styled.div``;

const Intro = styled.div`
  margin-top: 30px;
  display: flex;
  ${mediaLg} {
    flex-direction: column;
    margin-top: 30px;
  }
`;

const SmallPara = styled.div`
  font-size: 1.15em;
  line-height: 1.5em;
  ${mediaLg} {
    text-align: center;
  }
  ${mediaMd} {
    font-size: 1.3em;
  }
  ${mediaSm} {
    font-size: 1.1em;
  }
`;

const Para = styled.div`
  font-size: 1.4em;
  line-height: 1.5em;
  margin-top: 30px;
  ${mediaLg} {
    text-align: center;
  }
  ${mediaMd} {
    font-size: 1.5em;
  }
  ${mediaSm} {
    margin-top: 10px;
    font-size: 1.15em;
  }
`;

const LargePara = styled.div`
  font-size: 1.6em;
  line-height: 1.5;
  ${mediaLg} {
    margin-top: 30px;
    text-align: center;
  }
  ${mediaMd} {
    font-size: 1.7em;
  }
  ${mediaSm} {
    font-size: 1.2em;
  }
`;

const PortraitWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Portrait = styled.div`
  max-width: 225px;
  min-width: 225px;
  max-height: 225px;
  min-height: 225px;
  background: url('/portrait.jpg');
  background-position: -35px -15px;
  border-radius: 50%;
  border: solid 3px var(--secondary-color);
  ${mediaMd} {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    background: url('/portrait_medium.jpg');
    background-position: -25px -15px;
    margin-right: 0px;
  }
  ${mediaSm} {
    max-width: 160px;
    min-width: 160px;
    max-height: 160px;
    min-height: 160px;
    margin-right: 0px;
    background: url('/portrait_small.jpg');
    background-position: 580px -15px;
  }
  margin-right: 40px;
`;

const Blurb = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-top: 30px;

  ${mediaLg} {
    margin-top: 40px;
  }

  ${mediaSm} {
    margin-top: 15px;
  }
`;

const HallOfFame = styled.audio`
  width: 80%;
  height: 50px;
  outline: none;
  margin: 0 auto;
  margin-top: 15px;

  ${mediaSm} {
    width: 100%;
  }
`;
