import React from 'react';
import styled from '@emotion/styled';
import { Container, ContentWrapper, Heading, SubHeading } from '../helpers';
import NavBar from '../NavBar';
import { EXPERIENCES } from './constants';
import { Project } from '../Projects/Project';
import { Project as ProjectType } from '../interfaces';
import { Link } from 'react-router-dom';
import { NAVIGATION } from '../NavBar/constants';

export default function Experience() {
  return (
    <Container>
      <NavBar />
      <ContentWrapper>
        <Heading>
          Work <b>Experience</b>
        </Heading>
        <SubHeading>
          Take a look at some of the <b>work</b> I've done!
        </SubHeading>
        <SubHeading>
          Or if you're in a <b>rush</b>, you can{' '}
          <Link to={NAVIGATION.RESUME.route} style={{ textDecoration: 'none' }}>
            <b style={{ textDecoration: 'underline' }}>
              check out my resume here
            </b>
          </Link>
        </SubHeading>
        <ExperienceList>
          {getExperiences().map((project: ProjectType) => {
            return <Project project={project} />;
          })}
        </ExperienceList>
      </ContentWrapper>
    </Container>
  );

  function getExperiences(): ProjectType[] {
    return Object.values(EXPERIENCES);
  }
}

const ExperienceList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
