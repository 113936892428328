import React from 'react';
import styled from '@emotion/styled';
import Navigation from '../Navigation';
import {
  ContentWrapper as ContentWrapperTemplate,
  FooterDots,
  Heading as HeadingTemplate,
} from '../helpers';
import { Helmet } from 'react-helmet';
import { mediaSm } from '../constants';

export default function Home() {
  return (
    <Container>
      <Helmet>
        <title>Aniket Jain</title>
      </Helmet>
      <Navigation />
      <ContentWrapper>
        <HomepageContentWrapper>
          <TitleWrapper>
            <Title>Hello, I'm Aniket.</Title>
            <Title>{`<I'm a coder. />`}</Title>
          </TitleWrapper>
          <IntroWrapper>
            <IntroPara>
              I have experience working with over 6 startups as a web developer
              and product manager.
            </IntroPara>
            <ValuesParaWrapper>
              <ValuesPara>
                I value <b>clarity</b>, <b>transparency</b>, and <b>empathy</b>.
              </ValuesPara>
              <ValuesPara>
                These ideals guide my approach to problem solving and life in
                general.
              </ValuesPara>
            </ValuesParaWrapper>
          </IntroWrapper>
        </HomepageContentWrapper>
      </ContentWrapper>
    </Container>
  );
}

const Title = styled(HeadingTemplate)`
  ${mediaSm} {
    font-size: 32px;
  }
`;

const Container = styled.div`
  height: 100vh;
  background: radial-gradient(
      ellipse 80vw 110vw at 50vw 10vh,
      var(--fiery-orange-rgb) 20vh,
      rgba(227, 152, 94, 0) 80vh
    ),
    radial-gradient(
      ellipse 60vw 60vw at 25vw 40vh,
      var(--crimson-rgb),
      var(--bright-maroon-rgb) 8vw,
      var(--rusty-red-rgb) 13vw,
      var(--violet-blue-crayola-rgb) 25vw,
      rgba(129, 43, 199, 0) 50vw
    ),
    radial-gradient(
      ellipse 70vw 90vw at 30vw 0vh,
      var(--light-coral-rgb),
      var(--crimson-rgb) 30vh,
      rgba(190, 0, 25, 0) 50vh
    ),
    linear-gradient(
      120deg,
      var(--violet-blue-crayola) 30vw,
      rgba(129, 43, 199, 0) 80vw
    );

  ${mediaSm} {
    height: 100vh;
    background: radial-gradient(
        ellipse 60vw 60vw at 5vw 5vh,
        var(--crimson-rgb),
        var(--bright-maroon-rgb) 10vw,
        var(--bright-maroon-rgb) 15vw,
        var(--violet-blue-crayola-rgb) 30vw,
        white 60vw,
        rgba(255, 255, 255, 0) 99vh
      ),
      linear-gradient(
        130deg,
        var(--violet-blue-crayola),
        white 70vw,
        rgba(255, 255, 255, 0) 100vw
      ),
      radial-gradient(
        ellipse 5vw 8vw at 5vw 100%,
        var(--crimson-rgb),
        var(--bright-maroon-rgb) 5vw,
        var(--bright-maroon-rgb) 10vw,
        var(--violet-blue-crayola-rgb) 15vw,
        rgba(129, 43, 199, 0) 30vw,
        rgba(129, 43, 199, 0) 60vw
      ),
      radial-gradient(
        ellipse 30vw 30vw at 80vw 100%,
        var(--medium-turquoise),
        var(--violet-blue-crayola-rgb) 8vw,
        rgba(129, 43, 199, 0) 20vw
      );
  }
`;

const ContentWrapper = styled(ContentWrapperTemplate)`
  padding: 8em;

  ${mediaSm} {
    height: 650px;
  }
`;

const HomepageContentWrapper = styled.div`
  margin: 0em 1.5em;

  ${mediaSm} {
    margin: 0px;
    margin-top: 80px;
  }
`;

const TitleWrapper = styled.div`
  ${mediaSm} {
    text-align: center;
  }
`;

const IntroWrapper = styled.div`
  margin-top: 40px;

  ${mediaSm} {
    text-align: center;
  }
`;

const IntroPara = styled.div`
  letter-spacing: 1px;
`;

const ValuesParaWrapper = styled.div`
  margin-top: 40px;
`;

const ValuesPara = styled(IntroPara)`
  margin-top: 12px;
`;
