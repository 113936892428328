import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Project as ProjectType } from '../interfaces';
import { CenteredDiv } from '../helpers';
import { mediaMd, mediaSm } from '../constants';
import { Github } from '@styled-icons/boxicons-logos/Github';
import { Globe } from '@styled-icons/entypo/Globe';

export function Project({ project }: { project: ProjectType }) {
  const [containerHover, setContainerHover] = useState(false);

  return (
    <Container
      onMouseEnter={() => setContainerHover(true)}
      onMouseLeave={() => setContainerHover(false)}
    >
      <ContentWrapper>
        <TopTab>
          <TitleWrapper>
            <Title>{project.title}</Title>
            {project.subtitle && (
              <SubTitleWrapper>
                <SubTitle>{project.subtitle}</SubTitle>
              </SubTitleWrapper>
            )}
          </TitleWrapper>
          <Date>{project.date}</Date>
        </TopTab>
        <InnerWrapper>
          <Image src={project.image} />
          <Description>
            {project.description.map((paragraph) => {
              return <Para dangerouslySetInnerHTML={{ __html: paragraph }} />;
            })}
          </Description>
        </InnerWrapper>
      </ContentWrapper>
      {project.title === 'Steve Jobs AI' && (
        <AudioWrapper hover={containerHover}>
          <SteveJobsAudio
            src="steve_speech.mp3"
            controls
            controlsList="nodownload"
          />
        </AudioWrapper>
      )}
      <BottomTab hover={containerHover}>
        {project.github && (
          <SectionWrapper href={project.github} target="_blank">
            <GithubSection showBorder={project.website ? true : false}>
              <BottomTabText hover={containerHover}>Github</BottomTabText>
              <GithubIcon hover={containerHover} />
            </GithubSection>
          </SectionWrapper>
        )}
        {project.website && (
          <SectionWrapper href={project.website} target="_blank">
            <WebsiteSection>
              <BottomTabText hover={containerHover}>Website</BottomTabText>
              <GlobeIcon hover={containerHover} />
            </WebsiteSection>
          </SectionWrapper>
        )}
      </BottomTab>
    </Container>
  );
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--secondary-color);
  margin: 0px 150px;
  ${mediaMd} {
    margin: 0 50px;
  }

  ${mediaSm} {
    margin: 0 10px;
  }
`;

const Container = styled.div`
  width: 90%;
  margin-top: 40px;
  transition: all 200ms ease-out;
  background: #555;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.4), -8px 0px 8px rgba(0, 0, 0, 0.4);

  :hover {
    transform: translateY(-8px);
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.4),
      -15px 0px 15px rgba(0, 0, 0, 0.4);
    background: #5c5c5c;
  }

  ${mediaSm} {
    width: 130%;
  }
`;

const Image = styled.img`
  height: 225px;
  object-fit: contain;

  ${mediaMd} {
    width: 335px;
  }

  ${mediaSm} {
    width: 300px;
    margin-bottom: 10px;
  }
`;

const Title = styled(CenteredDiv)`
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  ${mediaMd} {
    font-size: 2.8em;
  }

  ${mediaSm} {
    font-size: 1.5em;
  }
`;

const SubTitleWrapper = styled.div`
  ${mediaSm} {
    border-top: 1px solid white;
    padding-top: 5px;
  }
`;

const SubTitle = styled(Title)`
  font-size: 1.5em;
  background: #444;
  margin-bottom: 10px;
  padding: 5px;

  ${mediaMd} {
    font-size: 1.7em;
  }

  ${mediaSm} {
    font-size: 1em;
  }
`;

const ContentWrapper = styled.div`
  padding: 15px;
  box-sizing: border-box;
`;

const InnerWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;

  ${mediaMd} {
    flex-direction: column;
    align-items: center;
  }
`;

const TopTab = styled.div`
  position: relative;

  ${mediaMd} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const Date = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 14px;
  box-sizing: border-box;
  font-size: 0.9em;
  background: #444;

  ${mediaMd} {
    position: static;
    display: inline-block;
    margin-top: 10px;
  }

  ${mediaSm} {
    font-size: 1em;
    padding: 4px 8px;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15px 0px 30px;
  font-size: 1.3em;
  line-height: 1.4em;
  justify-content: center;

  ${mediaSm} {
    font-size: 1em;
    padding: 0px;
  }
`;

const Para = styled.div`
  margin-top: 5px;
  font-size: 0.65em;
  ${mediaSm} {
    font-size: 0.8em;
    margin-top: 0px;
    margin-bottom: 3px;
    text-align: center;
  }
`;

const BottomTab = styled.div<{ hover: boolean }>`
  display: flex;
  transition: background 200ms ease-out;
`;

const SectionWrapper = styled.a`
  flex: 1;
  border-top: 1px solid white;
  cursor: pointer;
  transition: background 200ms ease-out;
  text-decoration: none;
  background: #555;
  :hover {
    background: #666;
  }
`;

const WebsiteSection = styled.div`
  margin: 15px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const GithubSection = styled(WebsiteSection)<{ showBorder: boolean }>`
  border-right: ${({ showBorder }) =>
    showBorder ? ' 1px solid var(--secondary-color)' : null};
`;

const GithubIcon = styled(Github)<{ hover: boolean }>`
  transition: color 200ms ease-out;
  height: 50px;
  width: 50px;

  color: ${({ hover }) => (hover ? 'var(--secondary-color)' : 'white')};

  ${mediaSm} {
    height: 35px;
    width: 35px;
  }
`;

const GlobeIcon = styled(Globe)<{ hover: boolean }>`
  transition: color 200ms ease-out;
  height: 45px;
  width: 45px;
  color: ${({ hover }) => (hover ? 'var(--secondary-color)' : 'white')};

  ${mediaSm} {
    height: 30px;
    width: 30px;
  }
`;

const BottomTabText = styled.div<{ hover: boolean }>`
  font-size: 1.5em;
  font-weight: bold;
  margin-right: 20px;
  transition: color 200ms ease-out;

  color: ${({ hover }) => (hover ? 'var(--secondary-color)' : 'white')};

  ${mediaSm} {
    font-size: 1em;
    margin-right: 10px;
  }
`;

const SteveJobsAudio = styled.audio`
  width: 70%;
  height: 50px;
  outline: none;
  padding-top: 15px;
  margin-bottom: 15px;
  ${mediaSm} {
    width: 100%;
  }
`;

const AudioWrapper = styled(CenteredDiv)<{ hover: boolean }>`
  transition: background 200ms ease-out;
  background: ${({ hover }) => (hover ? 'var(--secondary-color)' : null)};
`;
