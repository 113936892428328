import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Logo } from '../Logo';
import MenuOption from './MenuOption';
import { NAVIGATION, NavItem } from './constants';
import { Github, LinkedinSquare } from '@styled-icons/boxicons-logos';
import { Email } from '@styled-icons/material-rounded/Email';
import { mediaSm } from '../constants';

export default function Navigation() {
  const [isGithubHovered, setIsGithubHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);

  return (
    <Container>
      <ContentWrapper>
        <LogoWrapper href={'https://aniketjn.com'}>
          <Logo size={40} color={'#000'} thickness={3} />
        </LogoWrapper>
        <MenuWrapper>
          {getNavigationOptions().map((navItem: NavItem) => {
            return <MenuOption navItem={navItem} />;
          })}
        </MenuWrapper>
        {window.innerWidth > 800 && (
          <SocialMediaLinks>
            <GithubButtonWrapper
              onMouseEnter={() => setIsGithubHovered(true)}
              onMouseLeave={() => setIsGithubHovered(false)}
              href="https://github.com/aniketjain576"
              target="_blank"
            >
              <Indicator isHovered={isGithubHovered} />
              <GithubButton height={50} width={50} />
            </GithubButtonWrapper>
            <LinkedinButtonWrapper
              onMouseEnter={() => setIsLinkedinHovered(true)}
              onMouseLeave={() => setIsLinkedinHovered(false)}
              href="https://www.linkedin.com/in/aniketjn/"
              target="_blank"
            >
              <Indicator isHovered={isLinkedinHovered} />
              <LinkedinButton height={50} width={50} />
            </LinkedinButtonWrapper>
            <EmailButtonWrapper
              onMouseEnter={() => setIsEmailHovered(true)}
              onMouseLeave={() => setIsEmailHovered(false)}
              href="mailto:aniketjn@umich.edu"
            >
              <Indicator isHovered={isEmailHovered} />
              <EmailButton height={50} width={50} />
            </EmailButtonWrapper>
          </SocialMediaLinks>
        )}
      </ContentWrapper>
    </Container>
  );

  function getNavigationOptions(): NavItem[] {
    return Object.values(NAVIGATION);
  }
}

const Container = styled.div`
  position: relative;
  z-index: 100;

  ${mediaSm} {
    padding: 2em 2em 0em 2em;
  }
`;

const LogoWrapper = styled.a`
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 2.5em;
  top: 2.5em;

  ${mediaSm} {
    position: static;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 1em;
  right: 2em;

  ${mediaSm} {
    flex-direction: row;
    position: static;
  }
`;

const SocialMediaLinks = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 1.5em;
  right: 2em;
`;

const GithubButtonWrapper = styled.a`
  display: flex;
  align-items: center;
`;

const LinkedinButtonWrapper = styled(GithubButtonWrapper)``;

const EmailButtonWrapper = styled(GithubButtonWrapper)``;

const GithubButton = styled(Github)`
  margin-bottom: 6px;
  cursor: pointer;
  color: black;
`;

const LinkedinButton = styled(LinkedinSquare)`
  margin: 6px 0px;
  cursor: pointer;
  color: black;
`;

const EmailButton = styled(Email)`
  margin-top: 6px;
  cursor: pointer;
  color: black;
`;

const Indicator = styled.div<{ isHovered: boolean }>`
  margin-right: 20px;
  box-sizing: border-box;
  height: 12px;
  width: 6px;
  border: ${({ isHovered }) => (isHovered ? '1px solid black' : null)};
  border-left: none;
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
`;
