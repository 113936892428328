import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import Navigation from '../Navigation';
import {
  Container,
  ContentWrapper,
  FooterDots,
  Heading as HeadingTemplate,
  SmallFooterDots,
} from '../helpers';
import { EXPERIENCES } from './constants';
import { Experience as ExperienceType } from './interfaces';
import Experience from './Experience';
import { mediaMd, mediaSm } from '../constants';

export default function Work() {
  return (
    <Container>
      <Helmet>
        <title>Aniket Jain | Work</title>
      </Helmet>
      <Navigation />
      <ContentWrapper>
        <WorkPageContentWrapper>
          <Heading>The Code</Heading>
          <ExperiencesWrapper>
            {getExperiences().map((experience: ExperienceType) => {
              return <Experience experience={experience} />;
            })}
          </ExperiencesWrapper>
        </WorkPageContentWrapper>
        {window.innerWidth > 800 ? <FooterDots /> : <SmallFooterDots />}
      </ContentWrapper>
    </Container>
  );

  function getExperiences(): ExperienceType[] {
    return Object.values(EXPERIENCES);
  }
}

const ExperiencesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const WorkPageContentWrapper = styled.div`
  position: relative;
  padding: 0em 3em;
  display: flex;
  flex-direction: column;

  ${mediaMd} {
    padding: 0em;
  }
`;

const Heading = styled(HeadingTemplate)`
  font-size: 44px;
  margin-bottom: 40px;

  ${mediaMd} {
    font-size: 40px;
  }

  ${mediaSm} {
    font-size: 28px;
    margin-bottom: 12px;
  }
`;
