import React from 'react';
import NavBar from '../NavBar';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { SecondaryColorText } from '../Home';
import { mediaMd, mediaSm } from '../constants';
import { Container, ContentWrapper, Heading } from '../helpers';
import { NAVIGATION } from '../NavBar/constants';

function Contact() {
  return (
    <Container>
      <NavBar />
      <ContentWrapper style={{ padding: '3em', paddingBottom: '1.3em' }}>
        <Heading>
          Contact <SecondaryColorText>Me</SecondaryColorText>
        </Heading>
        <Description>
          Hi! Please <b>feel free</b> to talk to me about my{' '}
          <Link to={NAVIGATION.ABOUT.route} style={{ textDecoration: 'none' }}>
            <b style={{ textDecoration: 'underline' }}>interests</b>
          </Link>
          , or <b>anything</b> in general!
        </Description>
        <Description100>
          I'm <b>really responsive</b> on <b>email</b>
        </Description100>
        <Description200>
          I usually reply within <b>6 hours</b>
        </Description200>
        <ContactWrapper href={'mailto:aniketjn@umich.edu'}>
          <ContactText>
            <b>Email:</b> aniketjn@umich.edu
          </ContactText>
        </ContactWrapper>
        <Description style={{ marginTop: '20px' }}>
          Or if you prefer, <b>message me</b> on <b>LinkedIn</b>!
        </Description>
        <ContactWrapper
          href="https://www.linkedin.com/in/aniketjn/"
          target="_blank"
        >
          <ContactText>
            <b>LinkedIn:</b> linkedin.com/in/aniketjn
          </ContactText>
        </ContactWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default Contact;

const Description = styled.div`
  margin-top: 15px;
  background: rgba(85, 85, 85, 0.6);
  box-sizing: border-box;
  font-size: 1.2em;
  padding: 10px 50px;
  display: table;
  ${mediaMd} {
    display: block;
    font-size: 1.2em;
  }
  ${mediaSm} {
    font-size: 1em;
    padding: 10px 20px;
  }
`;

const Description100 = styled(Description)`
  margin-left: 100px;
  padding-right: 100px;
  ${mediaMd} {
    margin-left: 0px;
    padding-right: 0px;
  }
`;

const Description200 = styled(Description)`
  margin-left: 200px;
  padding-right: 200px;
  ${mediaMd} {
    margin-left: 0px;
    padding-right: 0px;
  }
`;

const ContactWrapper = styled.a`
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 5px solid white;
  padding: 15px 8px;
  width: 80%;
  text-decoration: none;
  outline: none;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-out;

  :hover {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
    transform: translateY(-7px);
  }

  @media (max-width: 1280px) {
    font-size: 2em;
  }

  ${mediaMd} {
    font-size: 1.5em;
    width: 100%;
  }

  ${mediaSm} {
    text-align: center;
    padding: 10px 8px;
    font-size: 1.1em;
  }
`;

const ContactText = styled.div`
  font-size: 1.2em;
`;
